<!-- =========================================================================================
    File Name: Calendar.vue
    Description: Calendar App
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="calendar-app">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        
    <div class="vx-card">
        <!-- <full-calendar class="w-full select-none" :events="calendarEventsView" lang="kr" @changeMonth="changeMonth" @dayClick ="openAddNewEvent" @eventClick="openEditEvent">

            <div slot="fc-header-left" class="flex flex-wrap sm:justify-start justify-center">
                <div v-for="(label, index) in calendarLabels" :key="index" class="flex items-center mr-4 mb-2">
                    <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
                    <span>{{ label.text }}</span>
                </div>
                <div v-if="$store.state.AppActiveUser.level >= 9">
                  <v-select style="width: 150px;" v-model="targetYear" :clearable="false" label="name" :options="yearSelect"/>
                  <vs-button type="border" color="warning" @click="overtimeStat(targetYear.value)">직원휴가현황</vs-button>
                </div>
                <div v-if="$store.state.AppActiveUser.level < 10" style="width: 100%;">
                  <p>연간 휴가 사용일수: {{userTimeOff.used}}</p>
                  <p>잔여 휴가일수: {{userTimeOff.remain - userTimeOff.used}}</p>
                </div>
            </div>

            <div slot="fc-header-right" class="flex justify-end">
                <vs-button icon-pack="feather" icon="icon-plus" @click="promptAddNewEvent(new Date())">Add</vs-button>
            </div>
            
        </full-calendar> -->
        
        <div slot="fc-header-left" class="flex flex-wrap sm:justify-start justify-center">
            <div v-for="(label, index) in calendarLabels" :key="index" class="flex items-center mr-4 mb-2">
                <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
                <span>{{ label.text }}</span>
            </div>
        </div>
        <div v-if="$store.state.AppActiveUser.level >= 9">
          <v-select style="width: 150px;" v-model="targetYear" :clearable="false" label="name" :options="yearSelect"/>
          <vs-button type="border" color="warning" @click="overtimeStat(targetYear.value)">직원휴가현황</vs-button>
        </div>
        <div v-if="$store.state.AppActiveUser.level < 10" style="width: 100%;">
          <p>연간 휴가 사용일수: {{userTimeOff.used}}</p>
          <p>잔여 휴가일수: {{userTimeOff.remain - userTimeOff.used}}</p>
        </div>
        <FullCalendar ref="fullCalendar" class="w-full select-none" :locale="locale"
                    defaultView="dayGridMonth"
                    :header="{
                      left: '',
                      center: 'title',
                      right: 'today prev,next',
                    }"
                    :googleCalendarApiKey="googleCalendarApiKey"
                    :dragScroll="false" :eventSources="eventSources"
                    :displayEventTime="false"
                    :plugins="calendarPlugins" 
                    @dateClick="openAddNewEvent"
                    @datesRender="changeMonth" @eventClick="openEditEvent" />

    </div>

    <!-- ADD EVENT -->
    <div class="photo-fullsize">
      <vs-popup
          class="calendar-event-dialog"
          title="휴가 신청"
          :active.sync="activePromptAddEvent">
          <v-select style="width: 300px;" placeholder="기자" label="name" v-model="writer_s"
            :options="writerOption" :clearable="false" :disabled="$store.state.AppActiveUser.level < 10"/>
          <div class="my-4">
              <small class="date-label">날짜 선택: </small>
              <datepicker type="date" range v-model="dateRange" lang="kr" :disabled="selectOneDay" style="width: 200px;" />
              <v-select style="margin-left: 20px; width: 200px; display: inline-block" v-model="type" :clearable="false" @input="changeType" :options="typeSelect"/>
          </div>
          <vs-textarea rows="5" label="휴가 사유" v-model="contents" />
          <vs-button type="border" style="float: right; margin: 0 50px 0 0;" @click="addEvent">휴가 신청</vs-button>
          <!-- <vs-button color="danger" type="border" style="float: right; margin: 0 50px 0 0;" @click="activePromptAddEvent=false">취소</vs-button> -->
      </vs-popup>
    </div>
    
    <!-- EDIT EVENT -->
    <div class="photo-fullsize">
      <vs-popup
          class="calendar-event-dialog"
          title="휴가 신청서 수정"
          :active.sync="activePromptEditEvent">
          <v-select style="width: 300px;" placeholder="기자" label="name" v-model="writer_s"
            :options="writerOption" :clearable="false" disabled/>
          <div class="my-4">
              <small class="date-label">날짜 선택</small>
              <datepicker type="date" range v-model="dateRange" :disabled="!(writer_s.idx == $store.state.AppActiveUser.id && state == 0 || $store.state.AppActiveUser.level >= 10 )" lang="kr" style="width: 200px;" />
              <v-select style="margin-left: 20px; width: 200px; display: inline-block" v-model="type" :clearable="false" :disabled="!(writer_s.idx == $store.state.AppActiveUser.id && state == 0 || $store.state.AppActiveUser.level >= 10 )" @input="changeType" :options="typeSelect"/>
          </div>
          <vs-textarea rows="5" label="휴가 사유" v-model="contents" :disabled="!(writer_s.idx == $store.state.AppActiveUser.id && state == 0 || $store.state.AppActiveUser.level >= 10 )" />
          <vs-button v-if="writer_s && writer_s.idx == $store.state.AppActiveUser.id && state <= 0" type="border" style="float: right; margin: 0 50px 0 0;" @click="editEvent(0)">휴가 신청서 수정</vs-button>
          <vs-button v-if="writer_s && writer_s.idx == $store.state.AppActiveUser.id && state <= 0 || $store.state.AppActiveUser.level >= 10" color="danger" type="border" style="float: right; margin: 0 50px 0 0;" @click="removeEvent">삭제</vs-button>
          <vs-button v-if="$store.state.AppActiveUser.level >= 9" type="border" style="float: right; margin: 0 50px 0 0;" @click="editEvent(1)">승인</vs-button>
          <vs-button v-if="$store.state.AppActiveUser.level >= 9" color="danger" type="border" style="float: right; margin: 0 50px 0 0;" @click="editEvent(-1)">반려</vs-button>

      </vs-popup>
    </div>
    <!-- Start: 통계 팝업 -->
    <div class="photo-fullsize">
      <vs-popup ref="popup-photo" classContent="popup-photo" title="" :active.sync="statModalActive">
        
        <vs-table ref="table" search :data="calendarStat">
          <template slot="thead">
            <vs-th sort-key="name">이름</vs-th>
            <vs-th sort-key="entry_date">입사일</vs-th>
            <vs-th sort-key="resetDate">휴가기준일</vs-th>
            <vs-th sort-key="usedTimeoff">휴가사용일수</vs-th>
            <vs-th sort-key="remainTimeoff">잔여승인일수</vs-th>
          </template>

            <template slot-scope="{data}">
              <tbody>
                <!-- <vs-tr :style="[tr.level >= 9 ? {'background-color': '#CCFFCC' } : tr.level >= 8 ? {'background-color': '#FFFFCC' } : {'background-color': '#DDCCFF'}]" :data="tr" :key="indextr" v-for="(tr, indextr) in data"> -->
                <vs-tr :style="[tr.level >= 9 ? {'background-color': '#ddFFdd' } : {'background-color': '#eeddFF'}]" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>{{ tr.name }}</vs-td>
                  <vs-td>{{ tr.entry_date }}</vs-td>
                  <vs-td>{{ tr.resetDate }}</vs-td>
                  <vs-td>{{ tr.usedTimeoff }}</vs-td>
                  <vs-td>{{ tr.remainTimeoff }}</vs-td>
                </vs-tr>
              </tbody>
            </template>
        </vs-table>
      </vs-popup>
    </div>
    <!-- End: 통계 팝업 -->

    </div>
</template>

<script>
import moment from 'moment';
import { setTimeout } from 'timers';

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import koLocale from '@fullcalendar/core/locales/ko';

export default {
  data() {
    return {
      userTimeOff:{
        used: 0,
        remain: 0,
      },
      calendarStat: [],
      targetYear: {name: '오늘 기준', value: moment()},
      yearSelect: [],
      currentMonth: '',
      dateRange: [new Date(), new Date()],
      contents: '',
      idx: 0,
      writer_s: {},
      state: 0,
      type: '연차',
      factor: 1,
      selectOneDay: false,
      writerOption: [],
      disabledFrom: false,
      labelLocal: 'none',
      settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.30,
      },
      typeSelect: [
        '연차',
        '반차',
        '특별휴가',
        '대체휴가',
      ],
      activePromptAddEvent: false,
      activePromptEditEvent: false,
      statModalActive: false,
      calendarEvents: [],
      calendarEventsView: [],
      calendarPlugins: [ dayGridPlugin, interactionPlugin, googleCalendarPlugin ],
      googleCalendarApiKey: 'AIzaSyBC448C8hsdo3l1z_9Gq2S9lQcKsphhwZk',
      eventSources: [
        {
          events: []
        },
        {
          events: [],
        },
        {
          events: [],
        },
        {
          googleCalendarId: 'ko.south_korea#holiday@group.v.calendar.google.com',
          editable: false,
          textColor: 'black',
          className: 'gcal-event'
        }
      ],
      locale: koLocale,
    }
  },
  components: {
      // 'full-calendar': require('vue-fullcalendar'),
      FullCalendar,
  },
  beforeRouteLeave (to, from, next) {
      this.activePromptAddEvent = false;
      this.activePromptEditEvent = false;
      setTimeout(() => { next(); }, 100);
  },
  computed: {
    calendarLabels() {
      return this.$store.state.timeOffLabels
    },
    labelColor() {
      return (label) => {
        if (label == "데스크") return "#28C76F"
        else if (label == "팀장") return "#FF9F43"
        else if (label == "기자 및 일반직원") return "#d3d606"
      }
    },
  },
  methods: {
    moment(data){
      return moment(data);
    },
    changeType(){
      if(this.type == '반차'){
        this.dateRange = [this.dateRange[0], this.dateRange[0]];
        this.selectOneDay = true;
        this.factor = 0.5;
      }else{
        this.selectOneDay = false;
        if(this.type == '연차'){
          this.factor = 1;
        }else{
          this.factor = 0;
        }
      }
    },
    overtimeStat(target){
      let route = this.$router.resolve({path: '/pages/overtimeStat', query: {target: target.format('YYYY-MM-DD')}});
      window.open(route.href, "overtimeStat","width=700,height=800,top=0,left=0,noresizable,toolbar=no,status=no,scrollbars=yes,directory=no");
      console.log(target)
      // this.calendarStat = JSON.parse(JSON.stringify(this.writerOption));
      // const thisIns = this;
      // this.$http.post('/api/board/getTimeOffListYear', {data: target})
      //   .then(function (response) {
      //     // console.log(response)
      //     for(let writer of thisIns.calendarStat){
      //       const entryDate = writer.entry_date;
      //       const resetDate = moment(entryDate).year(target.format('YYYY'))
      //       if(target > resetDate){
      //         writer.startDate = resetDate.format('YYYY-MM-DD');
      //         writer.endDate = resetDate.add(1, 'year').format('YYYY-MM-DD');
      //       }else{
      //         writer.endDate = resetDate.format('YYYY-MM-DD');
      //         writer.startDate = resetDate.subtract(1,'year').format('YYYY-MM-DD');
      //       }
      //       writer.usedTimeoff = 0;
      //       writer.remainTimeoff = writer.max_timeoff;
      //       for(let timeoff of response.data.filter(e => e.writer == writer.name && moment(e.start) > moment(writer.startDate) && moment(e.start) < moment(writer.endDate))){
      //         // console.log(timeoff, writer.startDate, writer.endDate)
      //         if(timeoff.state >= 0){
      //           if(timeoff.factor == 1){
      //             const days =  moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
      //             writer.usedTimeoff += days;
      //             writer.remainTimeoff -= days;
      //           }else if(timeoff.factor){
      //             const days =  moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
      //             writer.usedTimeoff += days * timeoff.factor;
      //             writer.remainTimeoff -= days * timeoff.factor;
      //           }
      //         }
      //       }
      //     }
      //     // for(const timeOff of response.data){
      //     //   const tmpIdx = thisIns.calendarStat.findIndex(e => e.name == timeOff.writer);
      //     //   const tmpDays = moment.duration(moment(timeOff.end).diff(moment(timeOff.start))).days() + 1;
      //     //   if(timeOff.state == 1){
      //     //     if(tmpIdx > -1){
      //     //       // thisIns.calendarStat[tmpIdx].timeOffReq += tmpDays;
      //     //       thisIns.calendarStat[tmpIdx].timeOffOk += tmpDays;
      //     //       if(thisIns.calendarStat[tmpIdx].level < timeOff.writer_level){ thisIns.calendarStat[tmpIdx].level = timeOff.writer_level; }
      //     //     }else{
      //     //       thisIns.calendarStat.push({ name: timeOff.writer, timeOffReq: 0, timeOffOk: tmpDays, level: timeOff.writer_level});
      //     //       // thisIns.calendarStat.push({ name: timeOff.writer, timeOffReq: tmpDays, timeOffOk: tmpDays, level: timeOff.writer_level});
      //     //     }
      //     //   }else if(timeOff.state == 0){
      //     //     if(tmpIdx > -1){
      //     //       thisIns.calendarStat[tmpIdx].timeOffReq += tmpDays;
      //     //       if(thisIns.calendarStat[tmpIdx].level < timeOff.writer_level){ thisIns.calendarStat[tmpIdx].level = timeOff.writer_level; }
      //     //     }else{
      //     //       thisIns.calendarStat.push({ name: timeOff.writer, timeOffReq: tmpDays, timeOffOk: 0, level: timeOff.writer_level});
      //     //     }
      //     //   }
      //     // }
      //     thisIns.calendarStat.sort((a,b) => b.level - a.level);
      //     thisIns.statModalActive = true;
      //   })
      //   .catch(function (error) {
      //     var errorText = error.toString();
      //     if(errorText.includes('401')){
      //       alert('로그인 해주세요!')
      //       thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
      //     }
      //     thisIns.$vs.notify({
      //       title:'Error',
      //       text: error,
      //       color:'danger',
      //       iconPack: 'feather',
      //       icon:'icon-alert-circle'})
      //     thisIns.$vs.loading.close(); 
      //   });  
    },
    addEvent() {
      console.log(this.writer_s)
      const obj = { start: moment(this.dateRange[0]).format('YYYY-MM-DD'), end: moment(this.dateRange[1]).format('YYYY-MM-DD'), writer: this.writer_s.name, 
                  writer_id: this.writer_s.idx, writer_level: this.writer_s.level, contents: this.contents, type: this.type, factor: this.factor};
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/setTimeOff', obj)
        .then(function () {
          thisIns.activePromptAddEvent = false;
          thisIns.$vs.notify({
            title:'성공',
            text: '휴가 신청 성공',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.getEventList(thisIns.currentMonth);
          thisIns.getUserTimeOff(thisIns.currentMonth, thisIns.writer_s.resetDate);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    clearFields(date) {
      this.contents = "";
      this.dateRange = [new Date(date), new Date(date)];
      this.writer_s = this.writerOption.find(e => e.idx == this.$store.state.AppActiveUser.id) || {};
      this.idx = 0;
      this.type = '연차';
      this.factor = 1;
      this.labelLocal = "none";
    },
    addNewEventDialog(date) {
      this.clearFields(date);
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(event) {
      let date = event.date;
      this.dateRange = [new Date(date), new Date(date)];
      this.addNewEventDialog(date);
    },
    promptAddNewEvent(date) {
      this.disabledFrom = false;
      this.addNewEventDialog(date);
    },
    openEditEvent(event) {
      if(event.event.id){
        const e = this.calendarEvents.find(e => e.idx == event.event.id);
        this.idx = e.idx;
        this.dateRange = [new Date(e.start), new Date(e.end)];
        this.writer_s = this.writerOption.find(e2 => e2.idx == e.writer_id) || {};
        this.state = e.state;
        this.type = e.type;
        this.factor = e.factor;
        this.contents = e.contents;
        this.disabledFrom = true;
        this.activePromptEditEvent = true;
      }
    },
    changeMonth(event){
      // console.log('event: ', moment(event.view.currentStart).format('YYYY-MM'));
      this.currentMonth = moment(event.view.currentStart).format('YYYY-MM');
      this.getEventList(this.currentMonth);
    },
    editEvent(state) {
      const obj = { idx: this.idx, start: moment(this.dateRange[0]).format('YYYY-MM-DD'), end: moment(this.dateRange[1]).format('YYYY-MM-DD'), writer: this.writer_s.name, 
                  writer_id: this.writer_s.idx, writer_level: this.writer_s.level, state, contents: this.contents, type: this.type, factor: this.factor};
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/editTimeOff', obj)
        .then(function () {
          thisIns.activePromptEditEvent = false;
          thisIns.$vs.notify({
            title:'성공',
            text: '휴가 신청서 수정 성공',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.getEventList(thisIns.currentMonth);
          thisIns.getUserTimeOff(thisIns.currentMonth, thisIns.writer_s.resetDate);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    removeEvent() {
      if(confirm('휴가신청을 삭제하시겠습니까?')){
        this.$vs.loading({
          scale: 1.5
        });
        const thisIns = this;
        this.$http.post('/api/board/deleteTimeOff', {idx: this.idx})
          .then(function () {
            thisIns.activePromptEditEvent = false;
            thisIns.$vs.notify({
              title:'성공',
              text: '휴가 신청서 삭제 성공',
              color:'primary',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.getEventList(thisIns.currentMonth);
            thisIns.getUserTimeOff(thisIns.currentMonth, thisIns.writer_s.resetDate);
            thisIns.$vs.loading.close();
          })
          .catch(function (error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
            }
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close(); 
          });
      }
    },
    getEventList(currentMonth){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/getTimeOffList', {data: currentMonth})
        .then(function (response) {
          console.log(response);
          thisIns.calendarEvents = response.data;
          thisIns.calendarEventsView = [];
          let cssClass, label, title, backgroundColor, borderColor;
          for(var i in thisIns.calendarEvents){
            if(thisIns.calendarEvents[i].state < 0 && thisIns.$store.state.AppActiveUser.level < 10 && thisIns.$store.state.AppActiveUser.id != thisIns.calendarEvents[i].writer_id){
              continue;
            }
            if(thisIns.calendarEvents[i].writer_level >= 9){
              cssClass = 'event-' + thisIns.labelColor('데스크');
              backgroundColor = thisIns.labelColor('데스크');
              borderColor = thisIns.labelColor('데스크');
              label = '데스크';
            }
            // else if(thisIns.calendarEvents[i].writer_level >= 8){
            //   cssClass = 'event-' + thisIns.labelColor('팀장');
            //   label = '팀장';
            // }
            else{
              cssClass = 'event-' + thisIns.labelColor('기자 및 일반직원');
              backgroundColor = thisIns.labelColor('기자 및 일반직원');
              borderColor = thisIns.labelColor('기자 및 일반직원');
              label = '기자 및 일반직원';
            }
            title = thisIns.calendarEvents[i].writer + '_' + thisIns.calendarEvents[i].type;
            if(thisIns.calendarEvents[i].state == 0){ title += ' (신청)' }
            else if(thisIns.calendarEvents[i].state == 1){ title += ' (승인)' }
            else if(thisIns.calendarEvents[i].state == -1){ 
              title += ' (반려)' 
              cssClass = 'event-danger';
            }
            thisIns.calendarEventsView.push({id: thisIns.calendarEvents[i].idx, idx: thisIns.calendarEvents[i].idx, title, 
              start: moment(thisIns.calendarEvents[i].start).format('YYYY-MM-DDT00:00:00'), end: moment(thisIns.calendarEvents[i].end).format('YYYY-MM-DDT23:59:59'), 
              backgroundColor, borderColor, textColor: 'black', allDay: false,
              state: thisIns.calendarEvents[i].state, level: thisIns.calendarEvents[i].writer_level, cssClass, label});
          }
          thisIns.calendarEventsView.sort((a,b) => moment(b.end).add(1,'day').diff(moment(b.start),'day') - moment(a.end).add(1,'day').diff(moment(a.start),'day'))
          thisIns.calendarEventsView = thisIns.calendarEventsView.filter(e => e.state == 0).concat(thisIns.calendarEventsView.filter(e => e.state != 0))
          
          thisIns.eventSources[0].events = thisIns.calendarEventsView;
          setTimeout(() => {
            // console.log(thisIns.$refs.fullCalendar.getApi().renderableEventStore);
            let calEvents = thisIns.$refs.fullCalendar.getApi().renderableEventStore.defs;
            let calEventsArray = [];
            // let calEventsArray2 = [];
            for(const key of Object.keys(calEvents)){
              if(calEvents[key].url && ['어버이날', '스승의날', '국군의날', '크리스마스 이브', '섣달 그믐날', '식목일'].findIndex(e => e == calEvents[key].title) == -1){
                calEventsArray.push({
                  start: moment(calEvents[key].publicId.substr(0,8)).format('YYYY-MM-DD'),
                  end: moment(calEvents[key].publicId.substr(0,8)).format('YYYY-MM-DD'),
                  rendering: 'background',
                  backgroundColor: 'rgba(255,180,180,1)',
                });
                // calEventsArray2.push({
                //   start: moment(calEvents[key].publicId.substr(0,8)).format('YYYY-MM-DD'),
                //   end: moment(calEvents[key].publicId.substr(0,8)).format('YYYY-MM-DD'),
                //   title: calEvents[key].title,
                //   textColor: 'black'
                // });
              }
            }
            if(currentMonth.substr(5,2) == "05"){
              calEventsArray.push({
                    start: moment(currentMonth + '-01').format('YYYY-MM-DD'),
                    end: moment(currentMonth + '-01').format('YYYY-MM-DD'),
                    rendering: 'background',
                    backgroundColor: 'rgba(255,180,180,1)',
              });
            }
            thisIns.eventSources[1].events = calEventsArray;
            // thisIns.eventSources[2].events = calEventsArray2;
            // console.log(thisIns.eventSources[1].events)
          }, 500);
          // console.log(thisIns.calendarEventsView);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });        
    },
    getUserTimeOff(currentMonth, entryDate){
      const thisIns = this;
      // if(!thisIns.userTimeOff.remain){
      //   // console.log('entry:', thisIns.writer_s.entry_date, moment().diff(moment(thisIns.writer_s.entry_date), 'year'));
      //   thisIns.userTimeOff.remain = 15 + parseInt(moment().diff(moment(thisIns.writer_s.entry_date), 'year') / 2);
      // }
      if(!thisIns.userTimeOff.remain){
        thisIns.userTimeOff.remain = 15;
      }
      let contYear = moment().diff(moment(thisIns.writer_s.entry_date), 'year');
      if(contYear == 0){
        thisIns.userTimeOff.remain = moment().diff(moment(thisIns.writer_s.entry_date), 'month');
      }else{
        thisIns.userTimeOff.remain = thisIns.userTimeOff.remain + parseInt((contYear - 1) / 2);
      }
      this.$http.post('/api/board/getUserTimeOff', {currentMonth, entryDate, writer_id: thisIns.$store.state.AppActiveUser.id })
        .then(function (response) {
          console.log('data: ',response)
          thisIns.userTimeOff.used = 0;
          for(const timeoff of response.data){
            if(timeoff.state >= 0){
              if(timeoff.factor == 1){
                const days =  moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
                thisIns.userTimeOff.used += days;
              }else if(timeoff.factor){
                const days =  moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
                thisIns.userTimeOff.used += days * timeoff.factor;
              }
            }
          }
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });  
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created(){
    const thisIns = this;
    this.currentMonth = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2);
    if(!this.$store.state.writerAll){
      this.$http.post('/api/getVarList/writer', {})
        .then(function (response) {
          console.log(response)
          thisIns.writerOption = response.data;
          for(let i in thisIns.writerOption){
            const resetDate = moment(thisIns.writerOption[i].entry_date).year(moment().format('YYYY'))
            if(moment() > resetDate){
              thisIns.writerOption[i].resetDate = resetDate.format('YYYY-MM-DD')
            }else{
              thisIns.writerOption[i].resetDate = resetDate.subtract(1,'year').format('YYYY-MM-DD')
            }
            thisIns.writerOption[i].entry_date = moment(thisIns.writerOption[i].entry_date).format('YYYY-MM-DD')
            // console.log(thisIns.writerOption[i].entry_date)
          }
          thisIns.$store.dispatch('SetWriterAll', {writerAll: thisIns.writerOption});
          thisIns.writer_s = thisIns.writerOption.find(e => e.idx == thisIns.$store.state.AppActiveUser.id) || {};
          thisIns.userTimeOff.remain = thisIns.writer_s.max_timeoff + 15;
          thisIns.getUserTimeOff(thisIns.currentMonth, thisIns.writer_s.resetDate);
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });  
      }else{
        this.writerOption = this.$store.state.writerAll;
        this.writer_s = this.writerOption.find(e => e.idx == this.$store.state.AppActiveUser.id) || {};
        thisIns.userTimeOff.remain = thisIns.writer_s.max_timeoff + 15;
        this.getUserTimeOff(this.currentMonth, thisIns.writer_s.resetDate);
      }
      this.yearSelect = [{name: '오늘 기준', value: moment()}]
      for(let year = parseInt(moment().format('YYYY')); year > 2018; year--){
        this.yearSelect.push({name: year + '-01-01 기준', value: moment(year + '-01-01')})
      }
      // console.log(this.currentMonth);
      // this.getEventList(this.currentMonth);
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/calendar.scss";
.calendar-event-dialog{
  .vs__search, .vs__search:focus{
    width: 0;
  }
  .vs__dropdown-menu{
    height: 220px;
  }
  .mx-input:disabled, .mx-input.disabled{
    color: #555;
  }
}
.mx-datepicker-popup{
  z-index: 100000;
}

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
#calendar-app{
  .fc-sat{
    background-color: rgba(180, 180, 255, 0.3);
  }
  .fc-sun{
    background-color: rgba(255, 180, 180, 0.3);
  }
  .gcal-event{
    pointer-events: none;
    display: none;
  }
}
</style> 